import { keyframes } from '@emotion/react'

export const upDownAnim1 = keyframes`
0% {
  transform: translateY(0);
}
50% {
  transform: translateY(-0.5rem);
}
100% {
  transform: translateY(0);
}
`
export const upDownAnim2 = keyframes`
0% {
  transform: translateY(-0.5rem);
}
50% {
  transform: translateY(0rem);
}
100% {
  transform: translateY(-0.5rem);
}
`
