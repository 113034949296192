import React from 'react'

import Cube from '@src/components/Cube'

import ScreenClients from '@assets/images/screen_clients.jpg'
import UIConsoleClientRating from '@assets/images/ui_console_clientrating.png'
import UIConsoleOrderInfo from '@assets/images/ui_console_orderinfo.png'

import MacBook from '@src/components/MacBook'
import Sphere from '@src/components/Sphere'
import BlockTypes from '@stage-ui/core/layout/Block/types'
import { Block } from '@stage-ui/core'
import { upDownAnim1, upDownAnim2 } from '@src/utils/keyframes'
import Orbits from '@src/components/Orbits'

const ControlSectionArt = (props: BlockTypes.Props) => {
  return (
    <Block {...props}>
      <Orbits
        scale={1.3}
        style={{
          top: '-0rem',
          left: '-0rem',
        }}
      />
      <MacBook
        screen={ScreenClients}
        style={{
          position: 'absolute',
          left: '-20rem',
          top: 0,
        }}
      />
      <Block
        shadow="xl"
        borderRadius="1rem"
        style={[
          {
            position: 'absolute',
            left: '-16rem',
            top: '-8rem',
            width: '21rem',
            height: '19.875rem',
            background: `url(${UIConsoleClientRating}) no-repeat`,
            backgroundSize: 'cover',
            animation: `${upDownAnim1} 8s ease-in-out infinite`,
            zIndex: 1,
            backdropFilter: 'blur(8px)',
          },
        ]}
      />
      <Block
        shadow="xl"
        borderRadius="0.5rem"
        style={[
          {
            position: 'absolute',
            right: '7rem',
            top: '6rem',
            width: '15rem',
            height: '9rem',
            background: `url(${UIConsoleOrderInfo}) no-repeat`,
            backgroundSize: 'cover',
            animation: `${upDownAnim2} 8s ease-in-out infinite`,
            zIndex: 1,
            backdropFilter: 'blur(8px)',
          },
        ]}
      />
      <Cube
        duration={20}
        reverse
        scale={1}
        style={{
          opacity: 0.9,
          position: 'absolute',
          filter: 'grayscale(0.9) brightness(1.3)',
          right: '18rem',
          top: '-9rem',
        }}
      />
      <Cube
        hue={150}
        duration={20}
        style={{
          opacity: 0.6,
          position: 'absolute',
          right: '-1rem',
          top: '8rem',
        }}
      />
      <Cube
        duration={20}
        scale={0.9}
        hue={30}
        reverse
        style={{
          opacity: 0.9,
          position: 'absolute',
          filter: 'grayscale(0.7) brightness(1.3) hue-rotate(230deg)',
          right: '25rem',
          top: '20rem',
        }}
      />
      <Sphere
        scale={0.4}
        style={{
          opacity: 0.8,
          position: 'absolute',
          right: '30rem',
          filter: 'grayscale(0.7) brightness(1.2) hue-rotate(230deg)',
          top: '30rem',
        }}
      />
    </Block>
  )
}

export default ControlSectionArt
