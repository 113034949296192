import SphereImage from '@assets/images/sphere.png'
import { Flexbox } from '@stage-ui/core'
import FlexboxTypes from '@stage-ui/core/layout/Flexbox/types'

type SphereProps = {
  scale?: number
  blur?: number
  hue?: number
} & FlexboxTypes.Props

const Sphere = (props: SphereProps) => {
  const { scale = 1, blur = 0, hue = 0, ...restProps } = props

  return (
    <Flexbox
      {...restProps}
      style={[
        {
          width: '6.25rem',
          transform: `scale(${scale || 1})`,
          opacity: 0.9,
          height: '6.25rem',
          background: `url(${SphereImage}) no-repeat`,
          backgroundSize: 'contain',
          backgroundPosition: 'center',
          zIndex: 1,
          filter: `hue-rotate(${hue}deg)${blur ? ` blur(${blur}px)` : ''}`,
          // @ts-ignore
          ...restProps.style,
        },
      ]}
    />
  )
}

export default Sphere
