/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { useMemo } from 'react'

import { keyframes } from '@emotion/react'
import { Block, Flexbox } from '@stage-ui/core'
import FlexboxTypes from '@stage-ui/core/layout/Flexbox/types'

import Sphere from './Sphere'

const Orbits = (
  props: FlexboxTypes.Props & {
    orbits?: boolean
    scale?: number
  },
) => {
  const { orbits, scale = 1, ...restProps } = props

  const { animationA, animationB } = useMemo(() => {
    const A = keyframes`
    0% {
      transform: rotate(0deg) scale(${scale}); 
    } 45% {
      transform: rotate(180deg) scale(${scale}); 
    } 100% {
      transform: rotate(360deg) scale(${scale}); 
    }`
    const B = keyframes`
    0% {
      transform: rotate(0deg) scale(${scale}); 
    } 45% {
      transform: rotate(-180deg) scale(${scale}); 
    } 100% {
      transform: rotate(-360deg) scale(${scale}); 
    }`
    return { animationA: A, animationB: B }
  }, [scale])

  return (
    <Flexbox
      centered
      borderColor="green200"
      borderWidth="0.0625rem"
      borderStyle="solid"
      borderRadius="100%"
      w="28rem"
      h="28rem"
      {...restProps}
      style={{
        zIndex: -1,
        animation: `${animationA} 200s infinite`,
        // @ts-ignore
        ...restProps.style,
      }}
    >
      <Flexbox
        centered
        borderColor="green200"
        borderWidth="0.0625rem"
        borderStyle="solid"
        borderRadius="100%"
        w="14rem"
        h="14rem"
        style={{
          animation: `${animationB} 120s infinite`,
        }}
      >
        <Sphere
          scale={0.15}
          hue={100}
          style={{
            position: 'absolute',
            left: '7.6rem',
            bottom: '-2rem',
          }}
        />
        <Block
          w="0.75rem"
          h="0.75rem"
          borderRadius="100%"
          backgroundColor="gray300"
          style={{
            position: 'absolute',
            left: '0rem',
            top: '4.25rem',
          }}
        />
        <Block
          w="1.25rem"
          h="1.25rem"
          borderRadius="100%"
          backgroundColor="gray200"
          style={{
            position: 'absolute',
            left: '13rem',
            top: '4rem',
          }}
        />
      </Flexbox>
      <Sphere
        scale={0.4}
        hue={30}
        style={{
          position: 'absolute',
          left: '1rem',
          top: '1rem',
        }}
      />
      <Block
        w="0.75rem"
        h="0.75rem"
        borderRadius="100%"
        backgroundColor="gray300"
        style={{
          position: 'absolute',
          left: '7.5rem',
          top: '1rem',
        }}
      />
      <Block
        w="1.25rem"
        h="1.25rem"
        borderRadius="100%"
        backgroundColor="gray200"
        style={{
          position: 'absolute',
          left: '24rem',
          top: '4.25rem',
        }}
      />
      <Block
        w="0.5rem"
        h="0.5rem"
        borderRadius="100%"
        backgroundColor="lightGreen200"
        style={{
          position: 'absolute',
          left: '27.6rem',
          top: '16rem',
        }}
      />
      <Block
        w="0.75rem"
        h="0.75rem"
        borderRadius="100%"
        backgroundColor="lightGreen200"
        style={{
          position: 'absolute',
          left: '27.45rem',
          top: '16rem',
        }}
      />
      <Block
        w="0.5rem"
        h="0.5rem"
        borderRadius="100%"
        backgroundColor="green400"
        style={{
          position: 'absolute',
          left: '27.125rem',
          top: '18rem',
        }}
      />
      <Block
        w="0.5rem"
        h="0.5rem"
        borderRadius="100%"
        backgroundColor="gray200"
        style={{
          position: 'absolute',
          left: '20.6rem',
          top: '26rem',
        }}
      />
      <Block
        w="0.75rem"
        h="0.75rem"
        borderRadius="100%"
        backgroundColor="gray200"
        style={{
          position: 'absolute',
          left: '1.125rem',
          top: '20rem',
        }}
      />
    </Flexbox>
  )
}

export default Orbits
