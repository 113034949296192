import MacBookHalf from '@assets/images/macbook_half.png'
import { Block, Flexbox } from '@stage-ui/core'
import FlexboxTypes from '@stage-ui/core/layout/Flexbox/types'

type MacBookProps = {
  screen: string
} & FlexboxTypes.Props
const MacBook = (props: MacBookProps) => {
  const { screen, children, ...restProps } = props
  return (
    <Flexbox {...restProps}>
      <Block
        style={{
          background: `url(${MacBookHalf}) no-repeat`,
          backgroundSize: '100%',
          width: '25.5rem',
          height: '30rem',
        }}
      >
        <Block
          style={{
            background: `url(${screen}) no-repeat`,
            backgroundSize: '100%',
            width: '154%',
            height: '100%',
            position: 'absolute',
            top: '3%',
            right: '-77%',
            zIndex: 1,
          }}
        />
      </Block>
      <Block
        style={{
          background: `url(${MacBookHalf}) no-repeat`,
          backgroundSize: '100%',
          transform: 'scaleX(-100%)',
          width: '25.5rem',
          height: '30rem',
        }}
      />
      {children}
    </Flexbox>
  )
}

export default MacBook
