import CubeImage from '@assets/images/cube.png'
import { keyframes } from '@emotion/react'
import { Flexbox } from '@stage-ui/core'
import FlexboxTypes from '@stage-ui/core/layout/Flexbox/types'

type CubeProps = {
  scale?: number
  duration?: number
  reverse?: boolean
  blur?: number
  hue?: number
} & FlexboxTypes.Props
const Cube = (props: CubeProps) => {
  const { reverse, scale = 1, blur = 0, duration = 20, hue = 0, ...restProps } = props

  const rotateAnimation = keyframes`
    0% {
      transform: rotate(0deg) scale(${scale || 1});
    }
    50% {
      transform: rotate(${reverse ? 180 : -180}deg) scale(${scale || 1});
    }
    100% {
      transform: rotate(${reverse ? 360 : -360}deg) scale(${scale || 1});
    }
  `

  return (
    <Flexbox
      {...restProps}
      style={[
        {
          width: '6.25rem',
          animation: `${rotateAnimation} ${duration}s linear infinite`,
          opacity: 0.9,
          height: '6.25rem',
          background: `url(${CubeImage}) no-repeat`,
          backgroundSize: 'contain',
          backgroundPosition: 'center',
          zIndex: 1,
          filter: `hue-rotate(${hue}deg)${blur ? ` blur(${blur}px)` : ''}`,
          // @ts-ignore
          ...restProps.style,
        },
      ]}
    />
  )
}

export default Cube
